/*body {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-family: 'Arial', sans-serif;*/
/*  background-image: url('../assets/background.png'); !* Replace 'background.jpg' with your image file *!*/
/*  background-position: center;*/
/*  background-repeat: no-repeat;*/
/*  background-attachment: fixed; !* Fix the background image *!*/
/*  overflow-x:hidden;*/
/*}*/

/*body{*/
/*  overflow-x:hidden;*/
/*}*/

/*img {*/
/*  transform-origin: center center;*/
/*  animation: zoom 30s infinite;*/
/*  max-width: 100%;*/
/*}*/

/*@keyframes zoom {*/
/*  0% {*/
/*    transform: scale(1);*/
/*  }*/
/*  50% {*/
/*    transform: scale(1.05);*/
/*    !* equals 105% *!*/
/*  }*/
/*  100% {*/
/*    transform: scale(1);*/
/*  }*/
/*}*/
.intro {
    background-image: url("../assets/background.png");

}
.resumeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  perspective: 1000px;
}

.resumeCard {
  text-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  color: #fff;
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
}

.ResumeCard p {
  margin: 5px 0;
}

.about {
  /*background-color: rgba(113, 123, 129, 0); !* Set the background color with opacity *!*/
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 1);*/
  background-image: url("../assets/background.png");
    background-size: cover;
  background-position: center;
  border-radius: 10px;
  /*box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);*/
  background-color: rgba(72, 5, 206, 0.07);
  box-shadow: 0 4px 8px rgb(122, 0, 253);
}

.education {
  background-color: rgba(46, 204, 113, 0.7);
}

.work-experience {
  background-color: rgba(231, 76, 60, 0.7);
}

.languages {
  background-color: rgba(238, 255, 0, 0.27);
  box-shadow: 0 4px 8px rgb(253, 249, 0);
}

.tools {
  background-color: rgba(7, 83, 248, 0.45);
  box-shadow: 0 4px 8px rgb(17, 0, 253, 1);
}

.technologies {
  background-color: rgba(255, 0, 242, 0.33);
  box-shadow: 0 4px 8px rgb(253, 0, 211, 1);
}

.os {
  background-color: rgba(7, 204, 248, 0.69);
  box-shadow: 0 4px 8px rgb(0, 253, 223, 1);
}

.frameworksAndLibraries {
  background-color: rgba(123, 7, 248, 0.73);
  box-shadow: 0 4px 8px rgb(93, 0, 253, 1);
}

.contact {
  background-color: rgba(37, 232, 30, 0.33);
  box-shadow: 0 4px 8px rgb(93, 253, 0, 1);
}

.certifications {
  background-color: rgba(0, 255, 178, 0.32);
  box-shadow: 0 4px 8px rgb(0, 253, 215);
}

.certificationsImages {
  border-radius: 20px;
  box-shadow: 0 4px 8px rgb(0, 0, 0);
}

.projects {
  background-color: rgba(255, 0, 0, 0.23);
  box-shadow: 0 4px 8px rgb(253, 0, 0);
}

.certsWebLink {
  margin-top: 10px;
  border-radius: 20px;
  text-underline: none;

}
a:link {
  text-decoration: none;
}

.urlLink{
  color: white;
}

.urlLink:hover {
  text-shadow: 0 4px 8px rgb(0, 253, 215);
 }