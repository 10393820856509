body{
    /*background: #7F7FD5;*/
    /*background: -webkit-linear-gradient(to right, #9192ea, #86A8E7, #7F7FD5);*/
    background: linear-gradient(to right, rgba(247, 255, 0, 0.08), rgba(166, 134, 231, 0.41), rgba(210, 127, 213, 0.43));
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(138, 24, 92, 0.17);
}

::-webkit-scrollbar-track {
  background: rgba(200, 0, 255, 0);
}